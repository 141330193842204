.container {
  max-width: 1920px;
  // padding: 0 1rem;
  margin: 0 auto;
  // max-height: 1000px;
  // height: 115vh;
  height: 100%;
  // margin-bottom: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  // background-image: url("../../../static/header.jpg");
  background: #f6f6f6;
  // background-repeat: repeat;
  // background-size: cover;
  // background-position: top;
  // opacity: 0.5;
  // background-color: rgba(255, 255, 255, 0.5);
  // background: rgb(99, 95, 95);
  // height: 100%;
  // overflow: hidden;
}

.container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../../static/backHeader.jpg");
  background-repeat: repeat-y;
  background-size: 100% auto;
  background-position: top;
  opacity: 0.1;
  z-index: -1; /* Размещаем фон за содержимым элемента */
}

@media (max-width: 768px) {
  .container {
    background: none; /* Убираем фоновый цвет для маленьких экранов */
    height: 100vh;
  }

  .container::before {
    background-size: cover;
    // opacity: 0.4;
  }
}

.noBackground::before {
  background-image: none;
}

.scrollbtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff8b;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

// .container::before {
//   content: "";
//   display: block; /* Делаем псевдоэлемент блочным элементом */
//   width: 100%;
//   max-height: 200px;
//   // height: 100%;
//   // height: auto;
//   // background-color: rgba(255, 255, 255, 0.5); /* Прозрачный белый фон */
//   z-index: -1; /* Помещаем псевдоэлемент за основной контент */
// }
