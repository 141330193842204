body {
  margin: 0;
  height: 100%;
  font-family: "Lora", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Lora";
  src: url("./fonts/Lora-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
}
